import React, {useState,useEffect} from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {Form, Modal} from "react-bootstrap";

import Col from 'react-bootstrap/Col'

import logo from "../assets/images/logo.png";
import logodesktop from "../assets/images/logo.png";
import useGlobalState from '../context/GlobalContextProvider';
import { useIntl, Link, changeLocale } from "gatsby-plugin-intl";
import { useCookies } from "react-cookie";
import {FormattedMessage} from "react-intl";
import ENV from "../../env";

import iosActionIcon from "../assets/images/ios-action-icon.png";
import iosAddIcon from "../assets/images/ios-add-icon.png";

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const Home = () => {
  const globalState = useGlobalState();
  const intl = useIntl();

  const [classCss, setClassCss] = useState('d-none');
  const [classCssActiveFr, setClassCssActiveFr] = useState('');
  const [classCssActiveNl, setClassCssActiveNl] = useState('');
  const [checkDomaine, setCheckDomain] = useState(false);
  const [showInstallMessage, setShowInstallMessage] = useState(false);

  const switchLocale = (locale) => {
    changeLocale(locale);
  };

  const redirectDomain = (url) => {
      //find & remove protocol (http, ftp, etc.) and get hostname
      let hostname;

      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      }
      else {
        hostname = url.split('/')[0];
      }

      //find & remove port number
      hostname = hostname.split(':')[0];
      //find & remove "?"
      hostname = hostname.split('?')[0];


      if ( ( (hostname === ENV.BE_YOUPLUS_URL) || (hostname === ENV.BE_YOUPLUS_URL_2) ) &&  !window.location.href.includes("-BE")) {
        changeLocale("fr-BE");
      }
      else if ( ( (hostname === ENV.NL_YOUPLUS_URL) || (hostname === ENV.NL_YOUPLUS_URL_2) ) &&  !window.location.href.includes("-NL")) {
        changeLocale("nl-NL");
      }
      else if ( ( (hostname === ENV.LU_YOUPLUS_URL) || (hostname === ENV.LU_YOUPLUS_URL_2) ) &&  !window.location.href.includes("-LU")) {
        changeLocale("fr-LU");
      }

      return hostname;
  }

  // Detects if device is on iOS
  const isIos = () => {
    const ua = window.navigator.userAgent;
    return /iP(ad|od|hone)/i.test(ua) && /WebKit/i.test(ua) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(ua));
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  useEffect(() => {
    if (!checkDomaine) {
      redirectDomain(window.location.href);
      setCheckDomain(true);
    }

    if(window.location.href.includes("-BE")) {
      setClassCss('lang-bar mb-4');
      if (window.location.href.includes("fr-BE")) {
        setClassCssActiveFr("active");
      }
      else {
        setClassCssActiveNl("active");
      }
    }

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessage(true);
    }
  }, []);

  const closeIosPopin = () => {
    setShowInstallMessage(false)
  }

  return (
  <Layout>
    <SEO title={intl.formatMessage({ id: "pages_meta_title.home" })}/>
    <BodyClass name="home" />
    {showInstallMessage &&
    <div className="ios-pwa-install-popin">
      <div className="close-ios-popin" onClick={() => closeIosPopin()}>x</div>
      <span dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "ios_popin.install-webapp" })}} /> <img src={iosActionIcon} className="ios-action-icon" />{intl.formatMessage({ id: "ios_popin.add-to-homescreen" })}
    </div>}

    <div className={typeof window !== 'undefined' && window.location.href.includes("fr") ? "home-cover home-cover-fr" : "home-cover"}></div>
    <div className="white-container">
      <div className="px-4">
        <div className={classCss}>
          <span className="icon-globe mr-1"></span>
          <span className={classCssActiveFr} onClick={() => switchLocale("fr-BE")}>
            {intl.formatMessage({ id: "0_login_home.lang_fr" })}</span>
          <span className="px-2">|</span>
          <span className={classCssActiveNl} onClick={() => switchLocale("nl-BE")}>
           {intl.formatMessage({ id: "0_login_home.lang_nl" })}
          </span>
        </div>
        <div className="text-center mb-4">
          <img src={logo} alt="logo" className="logo d-inline-block d-md-none" />
          <img src={logodesktop} alt="logo" className="logo-desktop d-none d-md-inline-block" width="300" />
        </div>
        <p className="text-center dark-grey">
          {intl.formatMessage({ id: "0_login_home.welcome_message" })}
        </p>
        <p className="text-center dark-grey">
          {intl.formatMessage({ id: "0_login_home.welcome_message_item_1" })}
        </p>
        <p className="text-center dark-grey">
          {intl.formatMessage({ id: "0_login_home.welcome_message_item_2" })}
        </p>
        <p className="text-center dark-grey">
          {intl.formatMessage({ id: "0_login_home.welcome_message_item_3" })}
        </p>
        <p className="text-center dark-grey">
          {intl.formatMessage({ id: "0_login_home.welcome_message_item_4" })}
        </p>
      </div>
      <div className="home-rewards row no-gutters">
        <Col xs={12} md={4} className="mb-3">
          <span className="icon-calendar-maintenance home-rewards__icon"></span>
          <div className="home-rewards__item">
            {intl.formatMessage({ id: "0_login_home.advantage1" })}
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <span className="icon-reward home-rewards__icon"></span>
          <div className="home-rewards__item">
            {intl.formatMessage({ id: "0_login_home.advantage2" })}
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <span className="icon-offers home-rewards__icon"></span>
          <div className="home-rewards__item">
            {intl.formatMessage({ id: "0_login_home.advantage3" })}
          </div>
        </Col>
      </div>


      <div className="px-4">
        <div className="mb-3 text-center">
          <Link to="/sessions/login" className="button red mx-auto">{intl.formatMessage({ id: "generic.forms.form_buttons.signin" })}</Link>
        </div>

        <p className="text-center">{intl.formatMessage({ id: "generic.forms.form_mentions.not_a_member" })}</p>

        <div className="mb-3 text-center">
          <Link to="/sessions/signup" className="button white mx-auto">{intl.formatMessage({ id: "generic.forms.form_buttons.signup" })}</Link>
        </div>
      </div>
    </div>
  </Layout>
)}

export default Home
